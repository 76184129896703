import Frontal from "../assets/frontal.JPG"
import Map from "../assets/MapChart_Map.png"
import Drums from "../assets/drums.png"
import Singapore from "../assets/travel_singapore.jpg"
import Thaliand from "../assets/travel_thailand.png"
import Scottland from "../assets/travel_scottland.png"
import Japan from "../assets/travel_japan.png"
import Dog from "../assets/toby.jpg"

export default function About() {
    return (
        <div className="mb-40">
        
            <Hero />
            <Where />
            <div className="h-48"/>
            <Hobbies />
            <div className="h-48"/>
            <Travel />
            <div className="h-48"/>
            <Special />
            <div className="h-48"/>
            <Next />
        </div>
    )
}

function Hero() {
    return(
        <div className="relative isolate px-6 pt-14 lg:px-8">
            {/* Design */}
            <div
                className="absolute inset-x-0 -top-60 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-120"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>

            <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-56">
                <div className="flex items-center gap-10 justify-center">
                    
                    <div>
                        <div className="items-center flex gap-3">
                            <p className="text-4xl tracking-tight sm:text-6xl text-gray-900">
                                About
                            </p>
                            <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                                me.
                            </p>
                        </div>
                        <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        Who I am outside of work.
                        </p>
                    </div>
                    <img src={Frontal} className="h-96 rounded-md shadow"/>
                </div>
            </div>

            {/* Design */}
            <div
                className="absolute inset-x-0 top-[30%] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[30%]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>

        </div>
    )
}

function Where() {
    return (
        <div className="mx-auto max-w-4xl">
            <div className="flex items-center gap-20 justify-center">
                <img src={Map} className="h-96"/>
                <div className="flex-col">
                    <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Where?
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        It's difficult to tell you where I am from.
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        I was born in a small town in Tamil Nadu called Tuticorin and spent my childhood in Delhi, Ahamedabad, Mumbai and Chennai.
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        I went to college in Pilani and work took me to <span className="text-blue-500">Bangalore</span>
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        Consequently, I'm familiar with four different languages and this makes me a stranger, both everywhere and nowhere!
                    </p>
                </div>
            </div>
        </div>
    )
}

function Hobbies() {
    return (
        <div className="mx-auto max-w-4xl">
            <div className="flex items-center gap-20 justify-center">
                <div className="flex-col">
                    <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Hobbies
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        Outside of the 9-5 hustle, you'll catch me jamming on my guitar and drums. I also love diving into video games, binge-watching anime, and try my hand at digital art at times.
                    </p>
                </div>
                <img src={Drums} className="h-72 rounded-md shadow"/>
            </div>
        </div>
    )
}

function Travel() {
    return(
        <div className="mx-auto max-w-4xl">
            <div className="flex flex-col items-center gap-10 justify-center">
                <div className="flex-col flex gap-10">
                    <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Travel
                    </p>
                    <div className="flex justify-between px-10">
                        <img src={Thaliand} className="h-48 rounded-md shadow"/>
                        <img src={Scottland} className="h-48 rounded-md shadow"/>
                        <img src={Japan} className="h-48 rounded-md shadow"/>
                        <img src={Singapore} className="h-48 rounded-md shadow"/>
                    </div>
                    <p className='text-2xl leading-8 text-gray-600 font-light'>
                        I have been around a bit — Singapore, U.A.E., U.K., Thailand, Japan, and more. My next adventure is Italy in March 2024 and my travel bucket list is always a work in progress.
                    </p>
                </div>
            </div>
        </div>
    )
}

function Special() {
    return (
        <div className="mx-auto max-w-4xl">
            <div className="flex items-center gap-20 justify-center">
                <img src={Dog} className="h-72 rounded-md shadow"/>
                <div className="flex-col">
                    <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Someone special
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        I've got a furry sidekick, my dog Toby, who's a pro at stealing my time and turning it into pure joy. He's the real boss around here.
                    </p>
                </div>
            </div>
        </div>
    )
}

function Next() {
    return (
        <div className="mx-auto max-w-4xl">
            <div className="flex items-center gap-20 justify-center">
                <div className="flex-col">
                    <p className="text-4xl tracking-tight text-blue-500 sm:text-6xl font-serif italic">
                        Aspirations
                    </p>
                    <p className='mt-2 text-2xl leading-8 text-gray-600 font-light'>
                        Peering into the future, I want to create something with lasting impact. Something meaningful and disruptive. Something that changes lives of people for the better. It is an ambitious dream, but as Steve Jobs once put it
                    </p>
                    <figure className="mt-10">
                        <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                        </svg>
                        <div className="flex items-center justify-center mt-2 text-4xl flex-col font-light italic bg-gradient-to-r from-blue-600 to-red-500 text-transparent bg-clip-text">
                            <blockquote>"The people who are crazy enough</blockquote>
                            <blockquote>to think they can change the world</blockquote>
                            <blockquote>are the ones who do."</blockquote>
                        </div>
                    </figure>
                </div>
            </div>
        </div>
    )
}
