import Hero from "../components/Hero"
import Projects from "../components/Projects"
import Testimonials from "../components/Testimonials"
import Timeline from "../components/Timeline"

export default function Portfolio() {
    return(
        <>
            <Hero />
            <Projects />
            <Timeline />
            <Testimonials />
        </>
    )
}