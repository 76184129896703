export default function Testimonials() {
    return (
        <section class="bg-white pb-40 pt-20" id="projects">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl tracking-tight text-blue-500 sm:text-4xl font-serif italic">Testimonials</h2>
            <p class="mt-2 text-lg leading-8 text-gray-600">Words of appreciation from some of the most talented people I have worked with.</p>
            </div>
            <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            
            <article class="flex max-w-xl flex-col items-start justify-between">
                <section class="bg-white dark:bg-gray-900">
                    <div class="max-w-screen-xl py-8 mx-auto text-center lg:py-16">
                        <figure class="max-w-screen-md mx-auto">
                            <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                            </svg>
                            <blockquote>
                                <p class="text-gray-900 dark:text-white font-light">Ishaan continually raises the bar of the team, and shows great ownership and bias for action - constantly improving our dashboards, metrics and pipelines. Keep it up!</p>
                            </blockquote>
                            <a href="https://in.linkedin.com/in/shyam-das-k-s-38b08132" target="_blank">
                            <figcaption class="flex items-center justify-center mt-6 space-x-3">
                                <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                    <div className="flex gap-1">
                                        <span class="bg-gradient-to-r from-blue-600 to-red-500 inline-block text-transparent bg-clip-text">@</span>
                                        <div class="pr-3 font-medium text-gray-900 dark:text-white">Shyam Das</div>
                                    </div>
                                    <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Sr Developer at Amazon</div>
                                </div>
                            </figcaption>
                            </a>
                        </figure>
                    </div>
                </section>
            </article>

            <article class="flex max-w-xl flex-col items-start justify-between">
                <section class="bg-white dark:bg-gray-900">
                    <div class="max-w-screen-xl py-8 mx-auto text-center lg:py-16">
                        <figure class="max-w-screen-md mx-auto">
                            <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                            </svg>
                            <blockquote>
                                <p class="text-gray-900 dark:text-white font-light">Ishaan was a great mentor who helped me improve as a developer. He would always encourage open conversations and we would always reach the right solution to issues.</p>
                            </blockquote>
                            <a href="https://in.linkedin.com/in/dhruv-nawani-4455231a0" target="_blank">
                            <figcaption class="flex items-center justify-center mt-6 space-x-3">
                                <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                    <div className="flex gap-1">
                                        <span class="bg-gradient-to-r from-blue-600 to-red-500 inline-block text-transparent bg-clip-text">@</span>
                                        <div class="pr-3 font-medium text-gray-900 dark:text-white">Dhruv Nawani</div>
                                    </div>
                                    <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Jr Developer at Amazon</div>
                                </div>
                            </figcaption>
                            </a>
                        </figure>
                    </div>
                </section>
            </article>

            <article class="flex max-w-xl flex-col items-start justify-between">
                <section class="bg-white dark:bg-gray-900">
                    <div class="max-w-screen-xl py-8 mx-auto text-center lg:py-16">
                        <figure class="max-w-screen-md mx-auto">
                            <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                            </svg>
                            <blockquote>
                                <p class="text-gray-900 dark:text-white font-light">Ishaan made sure that I was learning the fundamental concepts and increasing the quality of my deliverables each time with a 2-way learning process.</p>
                            </blockquote>
                            <a href="https://in.linkedin.com/in/naveen-kumar-01b45419a" target="_blank">
                            <figcaption class="flex items-center justify-center mt-6 space-x-3">
                                <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                    <div className="flex gap-1">
                                        <span class="bg-gradient-to-r from-blue-600 to-red-500 inline-block text-transparent bg-clip-text">@</span>
                                        <div class="pr-3 font-medium text-gray-900 dark:text-white">Naveen Kumar</div>
                                    </div>
                                    <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Intern at Amazon</div>
                                </div>
                            </figcaption>
                            </a>
                        </figure>
                    </div>
                </section>
            </article>

            </div>
        </div>
        </section>
    )
}
